
div.home section.querySelector{
    max-width: 600px;
    margin: 25px auto 0;
    background: white;
    padding: 20px 75px 20px 0px;
    border-radius: 25px;
    border: 7px black solid;
    box-shadow: 4px -2px 0px 2px #F29C39;
}

@media (max-width:1000px){
	div.home section.querySelector{
		padding: 20px 75px 20px 20px;
	}
}

ul.queryOptions{
	margin-top: 25px;
	justify-content: flex-start;
}

ul.queryOptions li {
	width: 50%;
	justify-content: flex-end;
	align-items: center;
	min-width: 125px;
}

h6{
	font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    padding-right: 10px;
    min-width: 125px;
    margin: 5px;
}


ul.queryOptions input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

ul.queryOptions label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 24px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

ul.queryOptions label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 2px;
	width: 18px;
	height: 18px;
	background: #fff;
	border-radius: 100%;
	transition: 0.1s;
}

ul.queryOptions input:checked + label {
	background: #105504;
}

ul.queryOptions input:checked + label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

ul.queryOptions label:active:after {
	width: 24px;
}

button{
	background: none;
    border: none;
    width: 100%;
    outline: none;
}

a.button{
	color: #F29C39;
	text-decoration: none;
	padding: 6px;
	background-color: black;
	border: 2px solid black;
	width: 125px;
	display: block;
	transition: all 0.2s ease;
	margin: 25px 0 0;
	font-size: 20px;
	text-align: center;
	float: right;
}
a.button:hover {
	color: black;
	background-color: #F29C39
}
