section.selectAction{
	justify-content: space-around;
	width: 75%;
    margin: 0 auto 30px;
}

button.button{
	background: white;
	color: black;
	font-size: 20px;
	border: 2px solid black;
	padding: 20px;
	width: 150px;
	padding: 14px 5px;
	transition: all 0.2s ease;
    margin-bottom: 10px;
}
button.button:hover{
	background: #105504;
	color: white;
	border-color: #105504;
}
button.button:active{
    background: #7CA67F;
}
/*.editDeclaration{
    width: 400px;
    background: linear-gradient(270deg, rgba(247,255,250,1) 0%, rgba(16,85,4,1) 58%, rgba(16,85,4,1) 100%); 
    margin-top: -30px;
    position: fixed;
    z-index: 9999;
    right: -309px;
    color: white;
    height: 85px;
    font-size: 30px;
    padding: 25px 20px;
}*/
.editDeclaration{
    margin-top: -30px;
}
section.workarea{
    background: RGBA(234, 234, 235, 1.00);
    width: 75%;
    margin: 25px auto;
    padding: 30px;
    border-radius: 5px;
}

section.workarea h2{
    font-size: 20px;
}

section.workarea h2, section.workarea p{
    text-align: center;
    margin-bottom: 2px;

}

section.workarea p{
    margin: 0;
    padding-top:0;
}

section.workarea button.button {
    margin: 30px auto 0;
    display: block;
}
section.workarea button.button.cancel{
   /* margin: 10px calc(40% + 20px) 0;*/
    border: 2px solid #3700DD;
    color: #3700DD;
}

section.workarea button.button.cancel:hover{
    background: #3700DD;
    color: white;
}
section.workarea button.button.cancel:active{
    background: RGBA(55, 0, 221, 0.5);
    color: white;
}

section.workarea button.button.redText{
    border: 2px solid #EC2B2B;
    color: #EC2B2B;
}

section.workarea button.button.redText:hover{
    background: #EC2B2B;
    color: white;
}
section.workarea button.button.redText:active{
    background: RGBA(236, 43, 43, 0.5);
    color: white;
}

div.edit section.workarea section.querySelector {
    margin: 0 auto;
}
section.workarea section.querySelector li, .sortableHelper {
    width: 100%;
    margin: 10px 0;
    font-size: 20px;
}
section.workarea section.querySelector li, .sortableHelper{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}
section.workarea section.querySelector li button:not(.delete), .sortableHelper button:not(.delete){
    font-size: 13px;
    width: 50px;
    font-size: 12px;
    padding: 3px 10px;
    background: white;
    margin-left: 40px;
}
section.workarea section.querySelector li button:not(.delete):hover{
    background:  #7CA67F;
    cursor: pointer;
}
section.workarea button.go {
    display: none;
} 
.addButtonForm div.form{
    width: 50%;
}
button.delete {
    color: #EC2B2B;
    display: inline-block;
    width: 100px;
    font-size: 30px;
} 
button.disabled {
    color: grey;
}
section.workarea button.disabled{
    cursor: default;
}
form.addButtonForm {
    border-top: 1px solid #656565;
    margin-top:30px;
    padding: 10px 30px 30px;
    
}

form.addButtonForm h2{
text-align: left;
font-size: 20px;
}

form.addButtonForm label{
    width: initial;
    margin: 0 15px 15px 0px;
    border-right: 1px ;
}

form.addButtonForm input{
    margin-left:5px;

}

button.redText{
    color: #EC2B2B;
    border-color: #EC2B2B;
}

button.deleteColumn{
    color: #EC2B2B;
    position: absolute;
    top: -5px;
    right: 0px;
    width: initial;
    font-size: 20px;
}

button.addCertificate{
    position: absolute;
}

