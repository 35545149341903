section.manageCertificates .half{
  width: 50%;
  text-align: center;
}
section.manageCertificates .dropzone{
  width: 200px;
  margin: 10px auto;
}
section.manageCertificates .dropzone p{
  padding: 20px;
}
section.manageCertificates span.error{
  padding-top: 10px;
  color: #EC2B2B;
  display: block;
}
section.manageCertificates span.error, section.manageCertificates .dropzone ul.files{
    padding-top: 10px;
}

div.addNewColumn input[name="columnName"]{
	color: black;
	border: none;
	background: transparent;
	outline: none;
	font-size: 12px;
	width: 100%;
	font-weight: 700;
	display: block;
	margin: 0 auto;
	resize:horizontal;
}

div.addNewColumn input[name="width"]{
	width: 50px;
}

div.columnNameField label{
	display: none;
}

div.addNewColumn form button.button {
    margin: 30px auto;
}



.formfield{
	padding: 14px 8px;
  text-align: center;
}

.react-resizable {
  position: relative;
  background: #F29C39;
  margin: 0 auto;
  border: 2px solid black;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: e-resize;
}

.imageContainer{
  width: 100px;
  position: relative;
}
.imageContainer div.overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom:0;
  color: #EC2B2B;
  font-size: 35px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.imageContainer:hover div.overlay{
  opacity: 1;
}