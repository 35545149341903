#certLightbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0,0,0,0.7);
    visibility: hidden;
    opacity: 0;
    overflow: scroll;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 80px;
}

.lightboxAndClose {
    position: relative;
    min-width: 400px;
}
@media (max-width: 440px){
  .lightboxAndClose {
    min-width: 300px;
  }
}

#certLightbox.lightboxOn{
	visibility: visible;
	opacity: 1;
}

.closeLightbox{
	position: absolute;
  top: 0px;
  right: 5px;
  font-size: 40px;
	color: #EC2B2B;
  cursor: pointer;
  opacity: 0;
}
.closeLightbox.show{
  opacity: 1;
}

#lightBoxImageWrapper img{
    display: block;
    margin: 0 auto;
}

#certLightbox img:last-of-type{
    padding-bottom: 90px;
}
div.removeCertificate{
    display: block;
    width: 100%;
    text-align: center;
    padding: 0 0 10px 0;
}

div.removeCertificate button{
    width: 200px;
}

.imageWrapper  {
  position: relative;
  cursor: zoom-in;
}
/*.img-zoom-lens {
  position: absolute;
  width: 50px;
  height: 50px;
}
.img-zoom-result {
  set the size of the result div:
  border: 1px solid red;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  position: absolute;
  left: -75px;
  top: -75px;
}*/

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 400px;
  height: 400px;
}

#loading{
  color: white;
  font-size: 30px;
}

/*#loading .svg-loader-container{
    width: 90px;
    margin: 0 auto;
}*/

#loading.hide{
  display: none;
}
