div.playerList{
    display: flex;
    user-select: none;
}
.svg-loader{
    width: 90px;
}
.svg-loader-container{
    text-align: center;
}
.hide {
    display: none;
}

input.search {
    position: absolute;
    left: 0px;
    font-size: small;
    top: 44px;
    width: 100%;
    padding: 5px 4px;
    background: white;
    border: 8px solid black;
    border-left-width: 4px;
    border-right-width: 4px;
    outline: none;
    color: black;
}
.public_fixedDataTable_header, .public_fixedDataTable_header .public_fixedDataTableCell_main {
    background-color: RGBA(242, 156, 57, 1.00) !important;
    color: black !important;
    background-image: none !important;
}
.public_fixedDataTableCell_columnResizerKnob {
    background-color: #2611e1 !important;
}
.public_fixedDataTableCell_main {
    border-color: black !important;
}
.public_fixedDataTable_main, .public_fixedDataTable_header, .public_fixedDataTable_hasBottomBorder {
    border-color: black !important;
}
.public_fixedDataTableRow_fixedColumnsDivider {
    border-color: black !important;
}
.fixedDataTableLayout_main {
    border-width: 2px !important;
    user-select: none !important;
}

.fixedDataTableCellGroupLayout_cellGroupWrapper {
    background: RGBA(242, 156, 57, 1.00) !important;
}


.retired{
    color: #EC2B2B;
}
.active{
    color: #105504;
}
.deceased{
    color: #000;
    font-weight: bold;

}
.name .public_fixedDataTableCell_cellContent {
    padding-left: 30px;
}


.playerNumber{
    position: absolute;
    font-size: 9px;
    top: 16px;
    left:2px;
    color:grey;
}
.playerNumber .noPlayers {
    position: absolute;
    background: #A6A6A6;
}

.noPlayers{
    background: #A6A6A6;
}

/* .name .noPlayers:after{
    content: "No Players fit that description";
} */
.certDot {
    color: grey;
    margin-left: 10px;
    cursor: pointer;
}

.active-row .active-column{
    background: RGBA(240, 155, 65, 0.2);
}

.public_fixedDataTable_header .fixedDataTableCellLayout_wrap3 {
    vertical-align: top;
}

/*.public_fixedDataTable_header .public_fixedDataTableCell_cellContent {
    padding: 14px 8px 8px;
}*/
.fixedDataTableLayout_main{
    background-color: grey;
}
.fixedDataTableCellLayout_wrap1{
    transition: all 0.15s ease;
}

div.edit div.public_fixedDataTable_bodyRow:hover .fixedDataTableCellLayout_wrap1{
    background-color: #7CA67F ;
}

.fixedDataTableCellGroupLayout_cellGroupWrapper{
    background: black;
}

a.heading-name-link{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.sorting-arrows span{
    display: block;
    line-height: 0.5;
    color: #ae7029;
    padding-left: 5px;
    font-size: 30px;
}
.sorting-arrows .arrow-highlight{
    color: #000;
}
