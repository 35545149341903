
div.controls{
	padding-bottom: 5px;
	align-items: flex-start;
	justify-content: space-between;
}

div.toggleCheckbox{
	display: flex;
	flex-flow: row nowrap;
	align-content: center;
}
div.controls h6{
	font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;
    padding-right: 0px;
    min-width: initial;
    margin: 5px;
}


div.toggleCheckbox input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

div.toggleCheckbox label {
	cursor: pointer;
    text-indent: -9999px;
    width: 45px;
    height: 20px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
    top: 2px;
    text-align: left;

}

div.toggleCheckbox label:after {
	content: '';
	position: absolute;
	top: 3px;
	left: 2px;
	width: 14px;
	height: 14px;
	background: #fff;
	border-radius: 100%;
	transition: 0.1s;
}

div.toggleCheckbox input:checked + label {
	background: #105504;
}

div.toggleCheckbox input:checked + label:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

div.toggleCheckbox label:active:after {
	width: 24px;
}
@media (max-width: 767px){
	div.toggleCheckbox{
		display: none;
	}
}

button.clearAllSearchs{
	background-color: grey;
	color: white;
    max-width: 125px;
    padding: 5px 15px;
	font-size: 12px;
	border-radius: 50px;
	transition: all 0.2s ease;
}
button.clearAllSearchs:hover{
	background-color: #105504;
}
div.legend{
	align-items: stretch;
		position: relative;
	left: -8px;
}
div.legend li {
	max-width: 210px;
}
div.legend h6{
	margin: 0 5px 0 0px;
	padding-right: 5px;
	border-right: 2px solid black;

}
div.legend .certDot{
	margin-left: 0;
}