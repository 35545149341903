div.form label{
    display: inline-block;
}

div.form label, div.radioWrapper h4.label{
    width: 40%;
    font-weight: 400 !important;
    text-align: right;
    margin: 10px;
}

div.radioWrapper{
    display: flex;
}

.workarea form{
	font-size: 16px;
}

.red-text{
	margin-left: calc(40% + 20px);
	color: #EC2B2B;
	font-size: 12px;
}

div.form input{
	padding: 3px;
    width: 200px;
}


div.editPlayerForm{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
   	background: rgba(0,0,0,0.7);
    padding: 80px;
	overflow: scroll;
}
.radioButtons{
    width: 300px;
    display: inline-block;

}

/*.radioButtons div.form{
    display: inline-block;
}*/
.radioButtons div.form label{
    width: initial;
}
.radioButtons div.form input{
    width: 25px;
}
.radioButtons div.form.otherInput input{
    width: 150px;
}