/*
=============================================================================
                  Custom Fonts
=============================================================================
*/


@font-face {
    font-family: 'Glyphter';
    src: url('./fonts/Glyphter.eot');
    src: url('./fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Glyphter.woff') format('woff'),
         url('./fonts/Glyphter.ttf') format('truetype'),
         url('./fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-A:before{content:'\0041';}
.icon-B:before{content:'\0042';}
.icon-C:before{content:'\0043';}
.icon-D:before{content:'\0044';}
.icon-E:before{content:'\0045';}
.icon-F:before{content:'\0046';}
.icon-G:before{content:'\0047';}
.icon-H:before{content:'\0048';}
.icon-I:before{content:'\0049';}
.icon-J:before{content:'\004a';}
.icon-K:before{content:'\004b';}
.icon-L:before{content:'\004c';}
.icon-M:before{content:'\004d';}
.icon-N:before{content:'\004e';}
.icon-O:before{content:'\004f';}
.icon-P:before{content:'\0050';}
.icon-Q:before{content:'\0051';}
.icon-R:before{content:'\0052';}
.icon-S:before{content:'\0053';}
.icon-T:before{content:'\0054';}
.icon-U:before{content:'\0055';}
.icon-V:before{content:'\0056';}
.icon-W:before{content:'\0057';}
.icon-X:before{content:'\0058';}
.icon-Y:before{content:'\0059';}
.icon-Z:before{content:'\005a';}
.icon-0:before{content:'\0030';}
.icon-1:before{content:'\0031';}
.icon-2:before{content:'\0032';}
.icon-3:before{content:'\0033';}
.icon-4:before{content:'\0034';}
.icon-5:before{content:'\0035';}
.icon-6:before{content:'\0036';}
.icon-7:before{content:'\0037';}
.icon-8:before{content:'\0038';}
.icon-9:before{content:'\0039';}
.icon-Exclaimation:before{content:'\0021';}
.icon-Comma:before{content:'\002c';}


/*
=============================================================================
                   Normalize, border box and clearfix
=============================================================================
*/

article,aside,details,figcaption,figure,footer,header,hgroup,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:700;}dfn{font-style:italic;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace, serif;font-size:1em;}pre{white-space:pre-wrap;word-wrap:break-word;}q{quotes:\201C \201D \2018 \2019;}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-.5em;}sub{bottom:-.25em;}img{border:0;}svg:not(:root){overflow:hidden;}fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,html input[type=button],/* 1 */
input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer;}button[disabled],input[disabled]{cursor:default;}input[type=checkbox],input[type=radio]{box-sizing:border-box;padding:0;}input[type=search]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}body,figure{margin:0;}legend,button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}

* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

.clearfix:after {
   content: " ";
   visibility: hidden;
   display: block;
   height: 0;
   clear: both;
}
ul, li {
	margin: 0;
	padding: 0;
	list-style: none;
}

img, svg {
	max-width: 100%;
}

svg {
	width: 100%;
}

body {
	font-family: 'Roboto Condensed', sans-serif;
	color: black;
	font-size: 12px;
}


.container {
	max-width: 1200px;
	margin: 0 auto;
}

a:hover svg path, a:hover {
    fill: #F29C39;
    color: #F29C39;
}
a.heading-name-link:hover{
	color: #000;
}

.flexMe{
	display: flex;
	flex-flow: row wrap;
}

/*----------------------------  HEADER & FOOTER ---------------------------*/

header, footer {
	background: black;
	position: relative;
	left: 0;
	right: 0;
	display: flex;
	flex-flow: row nowrap;
	font-family: 'Glyphter', sans-serif;
	text-transform: uppercase;
	letter-spacing: -2px;
	font-size: 13px;
	z-index: 999;
}
span.reg{
	font-family: 'Roboto Condensed', sans-serif;
	letter-spacing: inherit;
	font-size: 18px;
}

/*Header*/

header {
	top: 0;
	justify-content: space-between;
	height: 30px;
	align-items: center;
}

header, header a{
	color: white;
	text-decoration: none;
}
header .menuLinks a{
	padding: 0 20px;
	outline: none;
}
header .menuLinks a:active, header .menuLinks a:focus{
	color: #F29C39;
}
header .menuLinks a span{
	padding: 4px;
}

header span.fa{
	font-size: 20px;
}

header > * {
	padding: 5px;
}

/*Footer*/

footer .svg-container {
	width: 28px;
}

footer { 
	height: 50px;
	bottom: 0;
	justify-content: space-between;
}

footer svg path, footer a {
	fill: #FFF;
	color: #FFF;
	text-decoration: none;
}
section.externalLink, section.socialFooter {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}
section.externalLink{
	padding-left: 10px;
}
section.socialFooter{
	padding-right: 10px;
}

section.socialFooter > *{
	padding: 10px;
}

/*----------------------------  HOME ---------------------------*/
.home{
	margin: 0;
	padding: 30px 30px 90px;
	width: 100%;
	height: calc(100vh - 77px);
	background-image: url(assets/bobbyorrdive.jpg);
	background-size: cover;
}


/*----------------------------  MAIN ---------------------------*/

main > div.page {
	margin: 0px 30px 90px;
}
.login{
	height: calc(100vh - 77px);
}

.results{
	min-height: 100vh;
}
div.title{
	padding: 40px 5px;
    min-width: 470px;
}

h1{
	font-family: 'Glyphter', sans-serif;
	color: #F29C39;
	letter-spacing: -32px;
	margin: 0;
	padding: 0;
	margin-left: -15px;
}

h1, h1 span.reg{
	font-size: 80px;
    line-height: 56px;
}
h1 span.reg{
	padding: 0 12px 0 20px;
}


h1.flipped{
	color: #000000;
    -moz-transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(18px, 14px);
    -o-transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(18px, 14px);
    -webkit-transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(18px, 14px);
    transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(17px, 14px);
}

.results h1{

	font-size: 52px;
    line-height: 43px;
    letter-spacing: -19px;


}
.results h1.flipped{
	transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(15px, 14px);

}
/*----------------------- Intro -------------------------------------*/
main > div.intro.page{
	max-width: 1200px;
	margin: 30px auto 90px;
	padding: 0px 15px;
	font-size: 18px;
}
.intro h1{ 
	padding: 30px 0px;
	margin-left: -30px;
}
h1 span.i{
	letter-spacing: -42px;
}
h1 span.space{
	padding: 0 20px;
}

.intro .querySelector {
	max-width: 600px;
	float: right;
}

p.no-padding{
	padding: 0;
	margin-top: -1em;
}
/*----------------------- Selected Results -------------------------------------*/

div.results section.querySelector{
	width: 40%;
}

div.results div.title {
	width: 40%;
}
section.topOPage{
	justify-content: space-between;
	align-items: center;
}

/*-----------------------  Media Queries -------------------------------------*/

@media (max-width: 1170px){
	button.addCertificate {
    	position: static;
    	width: 100%;
	}

}

@media (max-width: 1000px){
div.results div.title{
	width: 100%;
	padding: 40px 5px 10px;
}
div.results section.querySelector{
	width: 100%;
	padding-bottom: 10px;
}
ul.queryOptions li{
	width: auto;
	padding-right: 20px;
}
.editDeclaration{
	margin-bottom: 30px;
}
section.workarea{
	width: 100%;
}
}



@media (max-width: 767px){
	.toggleCheckbox{
		display: none;
	}
	h1 span.i{
	letter-spacing: -30px;
	}

	div.results section.querySelector{
		width: 103%;
		margin-right: -25px;
	}
	section.topOPage, section.selectAction{
		flex-flow: column nowrap;
		justify-content: center;
	}
	.editDeclaration, section.selectAction button.button {
    text-align: center;
	}
	section.selectAction button.button{
		margin: 5px auto;
	}
	h1 {
	    font-size: 52px;
	    text-align: center;
	    letter-spacing: -22px;
	}
	h1.flipped{
		transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(16px, 15px);
	}
	main > div.selectedresults{
		margin: 30px 30px 90px 15px;
	}
	div.editPlayerForm{
		padding: 40px 10px;
	}
}
@media (max-width: 540px){
	header, footer{
		font-size: 11px;
	}
	main > div.page {
    	margin: 30px 12px 90px;
	}
	h1 span.i{
		letter-spacing: -19px;
	}
	div.title {
    	padding: 55px 5px 20px;
    	min-width: auto;
	}
	h1, .results h1, h1 span.reg {
	    font-size: 34px;
	    line-height: 36px;
	    letter-spacing: -14px;
	}
	.results h1.flipped, h1.flipped{
		transform: scale(1, -1) scaleY(0.7) skew(-20deg) translate(13px, 23px);
	}
	ul.queryOptions{
		flex-flow: column nowrap;
		align-items: center;

	}
	ul.queryOptions li{
		width: 100%;
		justify-content: center;
	}
	div.home section.querySelector{
		padding: 0px;
	}
	
	a.button{
		margin: 25px auto 20px;
		float: none;
		text-align: center
	}
	div.results div.title{
		padding: 40px 5px 0px;
	}
	section.manageCertificates .half{
		width: 100%;
	}
}
@media (max-width: 475px){
	div.form label, div.form input{
		text-align: left;
		width: 100%;
	}
}